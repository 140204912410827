.App {
        color: white;
    min-height: 100vh;
    background-color: #d3ae09;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.App-logo {
    height: 10vmin;
    fill: white;
}

.App-header {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: calc(50px + 2vmin);
}

.App-subtitle {
    text-align: right;
}


.App-link {
    color: #09d3ac;
}
